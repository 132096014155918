import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs/operators';
import { first, of, withLatestFrom } from 'rxjs';
import { Router } from '@angular/router';
import { MembershipRenewActions } from './membership-renew.actions';
import { PaymentActions } from '../payment/payment.actions';
import { Store } from '@ngrx/store';
import { MembershipRenewFormStep } from './membership-renew.models';
import { getMembershipNumber, MembershipActions } from '@aaa/emember/store-membership';
import { updateTreeValidity } from '../../modules/share/form.utils';
import { MembershipRenewVm } from '../../modules/membership-renew/membership-renew.vm';
import { NotificationService } from '../../modules/share/notification/notification.service';

@Injectable({ providedIn: 'root' })
export class MembershipRenewEffects {
  actions$ = inject(Actions);
  store = inject(Store);
  membershipRenewVm = inject(MembershipRenewVm);
  notification = inject(NotificationService);
  router = inject(Router);

  validateFormStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipRenewActions.validateFormStep),
      switchMap(({ validateStep, params }) => {
        if (validateStep === MembershipRenewFormStep.PAYMENT) {
          const pay$ = of(null).pipe(
            tap(() => this.store.dispatch(MembershipRenewActions.validateBilling())),
            switchMap(() => this.actions$.pipe(ofType(MembershipRenewActions.validateBillingSucceeded), first())),
            tap(() => this.store.dispatch(PaymentActions.validate())),
            switchMap(() => this.actions$.pipe(ofType(PaymentActions.validateSucceeded), first())),
            tap(() => this.store.dispatch(PaymentActions.generateToken())),
            switchMap(() => this.actions$.pipe(ofType(PaymentActions.generateTokenSucceeded), first())),
            tap(({ paymentToken }) =>
              this.store.dispatch(
                MembershipRenewActions.pay({
                  params: {
                    ...params,
                    payment: {
                      ...params.payment,
                      token: paymentToken,
                    },
                  },
                }),
              ),
            ),
            switchMap(() => this.actions$.pipe(ofType(MembershipRenewActions.paySucceeded), first())),
            map(() => MembershipRenewActions.setFormActiveStep({ activeStep: validateStep })),
          );

          return pay$;
        }

        return of(MembershipRenewActions.setFormActiveStep({ activeStep: validateStep }));
      }),
    ),
  );

  showNotifications$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MembershipRenewActions.validateBillingFailed),
        tap(() => this.notification.clear()),
        tap(() => {
          if (this.membershipRenewVm.billingFormGroup.controls.billingTo.controls.firstName.invalid) {
            this.notification.show('Please complete First Name');
          }

          if (this.membershipRenewVm.billingFormGroup.controls.billingTo.controls.lastName.invalid) {
            this.notification.show('Please complete Last Name');
          }

          if (this.membershipRenewVm.billingFormGroup.controls.billingTo.controls.address1.invalid) {
            this.notification.show('Please complete Address');
          }

          if (this.membershipRenewVm.billingFormGroup.controls.billingTo.controls.city.invalid) {
            this.notification.show('Please complete City');
          }

          if (this.membershipRenewVm.billingFormGroup.controls.billingTo.controls.zipcode.invalid) {
            this.notification.show('Please complete Zip Code');
          }
        }),
      ),
    { dispatch: false },
  );

  validateBilling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipRenewActions.validateBilling),
      map(() => {
        const isInValid = this.membershipRenewVm.formGroup.controls.billing.status !== 'VALID';

        if (isInValid) {
          updateTreeValidity(this.membershipRenewVm.formGroup.controls.billing);

          return MembershipRenewActions.validateBillingFailed();
        }

        return MembershipRenewActions.validateBillingSucceeded();
      }),
    ),
  );

  loadMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipRenewActions.paySucceeded),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getMembershipNumber)))),
      map(([{ accountDetails }, membershipNumber]) => {
        if (accountDetails) {
          return MembershipActions.updateAccountDetails({ accountDetails });
        }

        return MembershipActions.load({ membershipNumber: String(membershipNumber) });
      }),
    ),
  );

  redirectToAccountPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MembershipRenewActions.paySucceeded),
        tap(() => this.router.navigate(['account'])),
      ),
    { dispatch: false },
  );
}
