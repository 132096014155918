import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MembershipRenewActions } from './membership-renew.actions';
import { of, withLatestFrom } from 'rxjs';
import { Operation, OperationExecutePayload } from '@aaa/interface-joinRenew-joinRenewLib';
import { FormGroupValue } from '../../modules/share/form.utils';
import { AccountDetails, ValidateSucceededResponseObject } from '../types/types';
import { PaymentForm } from '@aaa/emember/share/payment-form';
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from '@aaa/interface-joinRenew-payment-paymentCybersource';
import { ExecuteService } from '../services/execute.service';
import { ClubApp } from '@aaa/emember/types';
import { getShortMembershipNumber } from '@aaa/emember/store-account';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { RequestError, RequestErrorType } from '../generic-errors';
import { MembershipRenewForm } from './membership-renew.models';
import {
  MembershipMzpGetMembershipCostsChange,
  MembershipMzpMethod,
  MembershipMzpOperationExecuteEventPayload,
  MzpMembershipLevel,
} from '@aaa/interface-joinRenew-membership-membershipMzp';
import { Mzp } from '../mzp.type';
import { getClearCacheSettings } from '../utils/get-cache-settings';
import { getMembershipRenewAccountDetails } from './membership-renew.selectors';
import { AnalyticsPurchaseEvent } from '../../../types/analytics-purchase-event';
import { DataLayerService } from '../../modules/share/services/data-layer.service';
import { getTransactionId } from '../utils/get-transaction-id';
import { AppAnalyticsEvents } from '../../../types/analytics-events';

@Injectable({ providedIn: 'root' })
export class MembershipRenewMzpSystemEffects {
  store = inject(Store);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.MidStates]));
  executeService = inject(ExecuteService);
  dataLayer = inject(DataLayerService);

  setSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipRenewActions.recostValidateSucceeded),
      map(({ response }: ValidateSucceededResponseObject<Mzp.MemberInfo>) => {
        const accountDetails = new Mzp.AccountInfo(response);
        return MembershipRenewActions.setAccountDetails({ accountDetails });
      }),
    ),
  );

  recostValidateMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipRenewActions.recostValidate),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(getMembershipRenewAccountDetails),
            this.store.select(getShortMembershipNumber),
          ),
        ),
      ),
      switchMap(([, accountDetails, membershipNumber]) =>
        this.recostValidate(accountDetails, membershipNumber).pipe(
          map((res) => MembershipRenewActions.recostValidateSucceeded(res)),
          catchError((error) => of(MembershipRenewActions.recostValidateFailed({ error }))),
        ),
      ),
    ),
  );

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipRenewActions.pay),
      switchMap(({ params }) =>
        this.pay(params.accountDetails, params.formValues, params.executionData, params.totalCost, params.payment).pipe(
          map((accountDetails) => MembershipRenewActions.paySucceeded({ accountDetails })),
          catchError((error) => of(MembershipRenewActions.payFailed({ error }))),
        ),
      ),
    ),
  );

  pay(
    accountDetails: AccountDetails | null,
    formValues: FormGroupValue<MembershipRenewForm>,
    executionData: string,
    totalCost: number,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> },
  ) {
    const membershipEvent: MembershipMzpOperationExecuteEventPayload = {
      cacheSettings: getClearCacheSettings(),
      executionData: executionData,
      method: MembershipMzpMethod.OPERATION_EXECUTE,
      operation: Operation.UPDATE,
    };
    const paymentEvent: PaymentCybersourceOperationExecuteEventPayload = {
      executionData: {
        flexMicroFormToken: payment.token,
        billTo: {
          address1: String(formValues.billing?.billingTo?.address1),
          address2: String(formValues.billing?.billingTo?.address2),
          administrativeArea: String(formValues.billing?.billingTo?.state),
          buildingNumber: '',
          country: 'US',
          district: String(formValues.billing?.billingTo?.state),
          email: String(accountDetails?.email || 'fallback@avagate.com'),
          firstName: formValues?.billing?.billingTo?.firstName || '',
          lastName: formValues?.billing?.billingTo?.lastName || '',
          locality: String(formValues.billing?.billingTo?.city),
          phoneNumber: String(accountDetails?.phone.cell),
          postalCode: String(formValues.billing?.billingTo?.zipcode),
        },
        amountDetails: {
          totalAmount: String(totalCost),
          currency: 'USD',
        },
        creditCardBrandedName: payment.formValues.card?.cardName || '',
      },
      method: PaymentCybersourceMethod.OPERATION_EXECUTE,
      operation: Operation.UPDATE,
    };
    const payload: OperationExecutePayload = {
      membershipEvent,
      paymentEvent,
      operation: Operation.UPDATE,
    };

    return this.executeService.execute<Mzp.MemberAssociateAddPayResponseObject>(payload).pipe(
      map(({ validateObject, paymentObject, operationObject }) => {
        // Todo: implement membership error and payment error message
        const paymentError = !!paymentObject?.meta?.isError;

        if (!validateObject) {
          throw new RequestError(RequestErrorType.RenewMembership, validateObject);
        }

        const analyticsEventParams: AnalyticsPurchaseEvent['eventParams'] = {
          currency: 'USD',
          transaction_id: getTransactionId(paymentObject),
          value: totalCost,
          items: [{ quantity: 1, price: totalCost, item_id: 'primary', item_name: AppAnalyticsEvents.AccountRenewal }],
          context: 'ava-store ' + AppAnalyticsEvents.AccountRenewal,
        };
        this.dataLayer.purchaseEvent(analyticsEventParams);

        return new Mzp.AccountInfo(validateObject.response.mzpResponse);
      }),
    );
  }

  recostValidate(accountDetails: AccountDetails | null, memberNumber: string) {
    const payload: MembershipMzpGetMembershipCostsChange = {
      memberNumber,
      flow: 'RENEW',
      method: MembershipMzpMethod.GET_MEMBERSHIP_COSTS_CHANGE,
      membershipLevel: accountDetails?.code?.level as MzpMembershipLevel,
      rv: accountDetails?.code?.rv,
    };

    return this.executeService.membershipQuery<Mzp.MembershipAddAssociateRecostValidationResponseObject>(payload).pipe(
      map((validateObject) => {
        // Todo: implement membership error message
        const membershipError = !!validateObject?.meta?.isError;

        return {
          response: validateObject.response.validationData,
          executionData: validateObject.response.executionData,
        };
      }),
    );
  }
}
