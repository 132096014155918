import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MembershipRenewActions } from './membership-renew.actions';
import { of, withLatestFrom } from 'rxjs';
import { ClearCacheSettings, Operation, OperationExecutePayload } from '@aaa/interface-joinRenew-joinRenewLib';
import { FormGroupValue } from '../../modules/share/form.utils';
import { AccountDetails, ValidateSucceededResponseObject } from '../types/types';
import { PaymentForm } from '@aaa/emember/share/payment-form';
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from '@aaa/interface-joinRenew-payment-paymentCybersource';
import { ExecuteService } from '../services/execute.service';
import { ClubApp } from '@aaa/emember/types';
import { getThreePointAuth } from '@aaa/emember/store-account';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { M } from '../m.type';
import {
  Membership,
  MembershipMGetMemberInfoEventPayload,
  MembershipMMethod,
  MembershipMOperationExecuteRenewEventPayload,
  ThreePointAuth,
} from '@aaa/interface-joinRenew-membership-membershipM';
import { expiredFromNow } from '../utils/expired-from-now';
import { RequestError, RequestErrorType } from '../generic-errors';
import { checkCybersourcePaymentValidation } from '../check-cybersource-payment-validation';
import { checkMembershipErrorsMSystem } from '../check-membership-errors-m-system';
import { MembershipRenewForm } from './membership-renew.models';
import { checkOperationErrorsMSystem } from '../check-operation-errors-m-system';
import { AnalyticsPurchaseEvent } from '../../../types/analytics-purchase-event';
import { DataLayerService } from '../../modules/share/services/data-layer.service';
import { getTransactionId } from '../utils/get-transaction-id';
import { AppAnalyticsEvents } from '../../../types/analytics-events';

@Injectable({ providedIn: 'root' })
export class MembershipRenewMSystemEffects {
  store = inject(Store);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Shelby, ClubApp.Northampton]));
  executeService = inject(ExecuteService);
  dataLayer = inject(DataLayerService);

  setSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipRenewActions.recostValidateSucceeded),
      map(({ response }: ValidateSucceededResponseObject<M.MemberInfoAndOfferingsResponseObject>) => {
        const membership = response.response.memberInfoResponse.Result.Membership[0];
        const offering = response.response.memberInfoResponse.Result.MembershipOffering;
        const accountDetails = new M.AccountInfo(membership, offering);

        return MembershipRenewActions.setAccountDetails({ accountDetails });
      }),
    ),
  );

  recostValidateMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipRenewActions.recostValidate),
      withLatestFrom(this.store.select(getThreePointAuth)),
      switchMap(([, threePointAuth]) =>
        this.recostValidate(threePointAuth).pipe(
          map((res) => MembershipRenewActions.recostValidateSucceeded(res)),
          catchError((error) => of(MembershipRenewActions.recostValidateFailed({ error }))),
        ),
      ),
    ),
  );

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipRenewActions.pay),
      withLatestFrom(this.store.select(getThreePointAuth)),
      switchMap(([{ params }, threePointAuth]) =>
        this.pay(
          params.accountDetails,
          params.formValues,
          params.executionData,
          params.totalCost,
          params.payment,
          threePointAuth,
        ).pipe(
          map((accountDetails) => MembershipRenewActions.paySucceeded({ accountDetails })),
          catchError((error) => of(MembershipRenewActions.payFailed({ error }))),
        ),
      ),
    ),
  );

  pay(
    accountDetails: AccountDetails | null,
    formValues: FormGroupValue<MembershipRenewForm>,
    executionData: string,
    totalCost: number,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> },
    threePointAuth: ThreePointAuth,
  ) {
    const cacheSettings: ClearCacheSettings = { cacheType: 'clear', clearContext: '' },
      membershipEvent: MembershipMOperationExecuteRenewEventPayload = {
        cacheSettings: cacheSettings,
        executionData: executionData,
        method: MembershipMMethod.OPERATION_EXECUTE,
        operation: Operation.UPDATE,
        threePointAuth: threePointAuth,
        autoRenew: !!payment.formValues.autoRenew,
      },
      paymentEvent: PaymentCybersourceOperationExecuteEventPayload = {
        executionData: {
          flexMicroFormToken: payment.token,
          billTo: {
            address1: String(formValues.billing?.billingTo?.address1),
            address2: String(formValues.billing?.billingTo?.address2),
            administrativeArea: String(formValues.billing?.billingTo?.state),
            buildingNumber: '',
            country: 'US',
            district: String(formValues.billing?.billingTo?.state),
            email: String(accountDetails?.email || 'fallback@avagate.com'),
            firstName: accountDetails?.firstName || '',
            lastName: accountDetails?.lastName || '',
            locality: String(formValues.billing?.billingTo?.city),
            phoneNumber: String(accountDetails?.phone.cell),
            postalCode: String(formValues.billing?.billingTo?.zipcode),
          },
          amountDetails: {
            totalAmount: String(totalCost),
            currency: 'USD',
          },
          creditCardBrandedName: payment.formValues.card?.cardName || '',
        },
        method: PaymentCybersourceMethod.OPERATION_EXECUTE,
        operation: Operation.UPDATE,
      },
      payload: OperationExecutePayload = { membershipEvent, paymentEvent, operation: Operation.UPDATE };

    return this.executeService.execute<M.ExecuteResponseObject, M.RenewExecutePaymentResponseObject>(payload).pipe(
      map(({ validateObject, paymentObject, operationObject }) => {
        const paymentError = !!paymentObject?.meta?.isError;
        if (paymentError) {
          checkCybersourcePaymentValidation(paymentObject.error);
        }

        if (!validateObject) {
          throw new RequestError(RequestErrorType.RenewMembership, validateObject);
        }

        const membershipError = !!validateObject?.meta?.isError;
        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject.error, validateObject);
        }

        const operationError = !!operationObject?.meta?.isError;
        if (operationError) {
          checkOperationErrorsMSystem(operationObject.error, operationObject);
        }

        const memberInfo = validateObject.response.response.Result.Membership[0] as Membership;
        const accountDetails = new M.AccountInfo(memberInfo);
        const analyticsEventParams: AnalyticsPurchaseEvent['eventParams'] = {
          currency: 'USD',
          transaction_id: getTransactionId(validateObject),
          value: totalCost,
          items: [{ quantity: 1, price: totalCost, item_id: 'primary', item_name: AppAnalyticsEvents.AccountRenewal }],
          context: 'ava-store ' + AppAnalyticsEvents.AccountRenewal,
        };
        this.dataLayer.purchaseEvent(analyticsEventParams);

        return accountDetails;
      }),
    );
  }

  recostValidate(threePointAuth: ThreePointAuth) {
    const payload: MembershipMGetMemberInfoEventPayload = {
      method: MembershipMMethod.GET_MEMBER_INFO,
      membershipNumber: threePointAuth.memberNumber || '',
      threePointAuth: {
        memberNumber: threePointAuth.memberNumber || '',
        lastName: threePointAuth.lastName || '',
        zip: threePointAuth.zip || '',
      },
    };

    return this.executeService.membershipQuery<M.MemberInfoAndOfferingsResponseObject>(payload).pipe(
      map((validateObject) => {
        const executionData = validateObject.response?.executionData || '';
        const membershipError = !!validateObject?.meta?.isError;

        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject.error, validateObject);
        }

        const account = new M.AccountInfo(validateObject.response.memberInfoResponse.Result.Membership[0]);
        const currentDate = account.expireDate ? new Date(account.expireDate) : null;

        if (currentDate && !expiredFromNow(currentDate)) {
          throw new RequestError(RequestErrorType.MembershipNotExpiredYet, validateObject);
        }

        return {
          executionData,
          response: validateObject,
        };
      }),
    );
  }
}
